<template>
  <div class="lease-filter">
    <h5 class="filter-title">Filter</h5>
    <div class="filter-form">
      <el-form :model="data">
        <el-form-item>
          <p class="search_tip">Arrival Date Range</p>
          <div class="search_text">
            <el-date-picker :clearable="true" v-model="data.arrival_date_gte" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" ></el-date-picker>
            <i class="icon-text">to</i>
            <el-date-picker :clearable="true" v-model="data.arrival_date_lte" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions1" ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <p class="search_tip">Departure Date Range</p>
          <div class="search_text">
            <el-date-picker v-model="data.departure_date_gte" :clearable="true" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions2" ></el-date-picker>
            <i class="icon-text">to</i>
            <el-date-picker :clearable="true" v-model="data.departure_date_lte" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions3" ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <p class="search_tip">Date of Contract</p>
          <div class="search_text">
            <el-date-picker v-model="data.created_date_gte" :clearable="true" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions4" ></el-date-picker>
            <i class="icon-text">to</i>
            <el-date-picker :clearable="true" v-model="data.created_date_lte" type="date" class="padding-right" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions5" ></el-date-picker>
          </div>
        </el-form-item>
        <!--新增  -->
        <div class="row ">
          <el-form-item>
            <p class="search_tip">Lease Number</p>
            <div class>
              <el-input clearable v-model="data.lease_id" type="number" />
            </div>
          </el-form-item>
          <el-form-item>
            <p class="search_tip">Tenant last Name Or Email</p>
            <div class="search_text">
              <el-input clearable v-model="data.tenant_last_name_or_email" />
            </div>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item>
            <p class="search_tip">Property Address</p>
            <div class="search_text">
              <el-input clearable  v-model="data.listing_address" />
            </div>
          </el-form-item>

          <el-form-item>
            <p class="search_tip">Agent Name</p>
            <div class="search_text">
              <el-input clearable v-model="data.user_name" />
            </div>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item>
            <p class="search_tip">Rent Amount</p>
            <div class="search_text">
              <el-input clearable v-model="data.rent_gte">
                <span slot="prefix" class="el-input__icon">$</span>
              </el-input>
              <i class="icon-text">to</i>
              <el-input clearable v-model="data.rent_lte">
                <span slot="prefix" class="el-input__icon">$</span>
              </el-input>
            </div>
          </el-form-item>

          <el-form-item>
            <p class="search_tip">Payment Status</p>
            <el-select v-model="data.status" clearable placeholder="Select">
              <el-option v-for="item in leaseStatus" :key="item.key" :label="item.name" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="lb_btn">
        <el-button class="cancel-btn" @click="clear">Cancel</el-button>
        <el-button class="apply-btn" @click="precisionSearch">Apply</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
        data: { },
        pickerOptions0: {
          disabledDate: (time) => {
            let arrivalDateVal = this.data.arrival_date_lte;
            if (arrivalDateVal) {
              return time.getTime() > new Date(arrivalDateVal).getTime();
            }
          },
        },
        pickerOptions1: {
          disabledDate: (time) => {
            let arrivalDateVal = this.data.arrival_date_gte;
            if (arrivalDateVal) {
              return time.getTime() < new Date(arrivalDateVal).getTime();
            }
          },
        },
        pickerOptions2: {
          disabledDate: (time) => {
            let departureDateVal = this.data.departure_date_lte;
            if (departureDateVal) {
              return time.getTime() > new Date(departureDateVal).getTime();
            }
          },
        },
        pickerOptions3: {
          disabledDate: (time) => {
            let departureDateVal = this.data.departure_date_gte;
            if (departureDateVal) {
              return time.getTime() < new Date(departureDateVal).getTime();
            }
          },
        },
        pickerOptions4: {
          disabledDate: (time) => {
            let createdDateVal = this.data.created_date_lte;
            if (createdDateVal) {
              return time.getTime() > new Date(createdDateVal).getTime();
            }
          },
        },
        pickerOptions5: {
          disabledDate: (time) => {
            let createdDateVal = this.data.created_date_gte;
            if (createdDateVal) {
              return time.getTime() < new Date(createdDateVal).getTime();
            }
          },
        },
   
    };
  },
   computed: mapState({
    leaseStatus: (state) => state.enum.leaseStatus
  }),
  methods: {
    ...mapActions(["getLeaseStatus"]),
    // 精准搜索
    precisionSearch() {
      this.$emit("precisionSearch", this.data);
    },
    // 清空
    clear() {
      this.$emit("close");
    },
  },
  mounted(){
    if (this.leaseStatus.length === 0) {
      this.getLeaseStatus();
    }
    if (this.$store.state.leasesFilter != null) {
      this.data = Object.assign(
        {},
        this.$store.state.leasesFilter,
        this.data
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.lease-filter {
  .filter-title {
    font-size: 16px;
    font-family: "Roboto-Bold", sans-serif;
    margin: 0;
    font-weight: normal;
  }
  .filter-form {
    .row{
      margin:0px;
    }
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
    .icon-text {
      font-style: normal;
      margin: 0 10px;
      width: 40px;
    }
    .search_tip {
      margin: 0;
      text-align: left;
    }
    /deep/ .el-select {
      width: 100%;
    }
    .row {
      justify-content: space-between;
      /deep/ .el-form-item {
        width: calc(calc(100% - 20px) / 2);
      }
    }
    .search_text {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .padding-right {
        /deep/ .el-input__inner {
          padding-right: 0;
        }
      }
      /deep/ .el-input__inner {
        font-size: 12px;
        line-height: normal;
      }
      /deep/ .el-date-editor.el-input,
      /deep/ .el-date-editor.el-input__inner {
        width: 100%;
      }
    }
    .select_state {
      text-align: left;
      margin-top: 45px;
      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        &:hover {
          border-color: #678993;
        }
        &::after {
          display: none;
        }
      }
      /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #606266;
      }
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border: none;
        background-image: url("../../../assets/img/true.png");
        background-size: cover;
      }
    }

    .lb_btn {
      text-align: right;
      padding: 20px 0 0px 0;
      .el-button {
        font-family: "Roboto-Medium", sans-serif;
        background: #678993 0% 0% no-repeat padding-box;
        color: #fff;
        font-size: 14px;
      }
      .cancel-btn {
        background-color: #e2eff6;
        color: #38425b;
      }
    }
  }
}
</style>