<template>
  <div class="leases_box">
    <Lease-Top-Search ref="topSearch"  @fuzzySearch="fuzzySearch" @selfFilter="selfFilter" @signedFilter="signedFilter"  @reset="reset" @filterSearch="filterSearch" @showCancelled="showCancelled" ></Lease-Top-Search>
    <div class="show-number">Show 1 - {{ offset + 25 > total ? total : offset + 25  | currencyPrice("", "", "0")}} of {{ total | currencyPrice("", "", "0") }} Leases</div>
    <div class="lease_tip" v-loading="loading">
      <LeaseTable :total="total" :data="data" @load="load" @pagesChanged="pagesChanged" @sort="sort" :currentPage="currentPage"></LeaseTable>
    </div>
  </div>
</template>
<script>
import { apiGetLeases } from "../../API/api";
import LeaseTopSearch from "./Component/LeaseTopSearch";
import LeaseTable from "./Component/LeasesTable";
import { validateObjectNull } from '../../js/null';
export default {
  data() {
    return {
      flag: false, // 用来标记是模糊搜索还是精准搜索，true表示模糊搜索
      search: "",
      leaseInfor: {},
      total: 1,
      self: true,
      signed: false,
      totalPage: 1, // 总页数
      currentPage: 0, // 当前页数
      limit: 25, // 每页显示数量
      data: [], //当前显示的数据
      loading: false,
      ordering: "",
      show_cancelled: false,
      nomore: false,
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {// 查询参数
      return {
        limit: this.limit,
        offset: this.offset,
        lease_id: this.leaseInfor.lease_id,
        arrival_date_gte: this.leaseInfor.arrival_date_gte,
        arrival_date_lte: this.leaseInfor.arrival_date_lte,
        departure_date_gte: this.leaseInfor.departure_date_gte,
        departure_date_lte: this.leaseInfor.departure_date_lte,
        created_date_gte: this.leaseInfor.created_date_gte,
        created_date_lte: this.leaseInfor.created_date_lte,
        rent_gte: this.leaseInfor.rent_gte,
        rent_lte: this.leaseInfor.rent_lte,
        tenant_last_name_or_email: this.leaseInfor.tenant_last_name_or_email,
        // owner_last_name_or_email: this.leaseInfor.owner_email,
        listing_address: this.leaseInfor.listing_address,
        user_name: this.leaseInfor.user_name,
        search: this.search,
        self: this.self,
        signed: this.signed,
        status: this.leaseInfor.status,
        ordering: this.ordering,
        show_cancelled: this.show_cancelled,
      };
    },
    // fuzzyQuery() {
    //   let obj = this.query;
    //   if (obj.lease_id === undefined) delete obj.lease_id;
    //   if (obj.arrival_date_gte === undefined) delete obj.arrival_date_gte;
    //   if (obj.arrival_date_lte === undefined) delete obj.arrival_date_lte;
    //   if (obj.departure_date_gte === undefined) delete obj.departure_date_gte;
    //   if (obj.departure_date_lte === undefined) delete obj.departure_date_lte;
    //   if (obj.created_date_gte === undefined) delete obj.created_date_gte;
    //   if (obj.created_date_lte === undefined) delete obj.created_date_lte;
    //   if (obj.rent_gte === null || obj.rent_gte === undefined)  delete obj.rent_gte;
    //   if (obj.rent_lte === null || obj.rent_lte === undefined) delete obj.rent_lte;
    //   // if (obj.owner_last_name_or_email === undefined)
    //   //   delete obj.owner_last_name_or_email;
    //   if (obj.tenant_last_name_or_email === undefined) delete obj.tenant_last_name_or_email;
    //   // if (obj.owner_email === undefined) delete obj.owner_email;
    //   if (obj.listing_address === undefined) delete obj.listing_address;
    //   if (obj.user_name === undefined) delete obj.user_name;
    //   if (obj.status === undefined) delete obj.status;
    //   if (obj.search === undefined || obj.search === "") delete obj.search;
    //   if(!obj.show_cancelled) delete obj.show_cancelled
    //   return obj;
    // },
  },
  components: { LeaseTopSearch, LeaseTable },
  methods: {
    reset() {
      this.currentPage = 1;
      this.self= true;
      this.show_cancelled = false;
      this.signed = false;
      this.search = "";
      this.ordering = ""
      this.leaseInfor = {};
      this.getLeases();
    },
    load(){
      if(!this.loading){
      if (this.nomore ) return; //到达底部不再执行
      this.currentPage += 1;
      if(this.offset>=0){
        this.getLeases();
      }}
    },
    // 排序
    sort(val) {
      this.currentPage = 1;
      if (val.order === "ascending") {
        this.ordering = val.prop;
      } else if (val.order === "descending") {
        this.ordering = "-" + val.prop;
      } else {
        this.ordering = "";
      }
      this.data = []
      this.getLeases();
    },
    // 查看租约
    leaseDetail(index) {
      this.$store.commit("setLeaseAgeent", false);
      this.$store.commit("setLeaseSecurityDeposit", false);
      this.$router.push({ name: "edit-lease", params: { id: index } });
    },
    // 获取租约列表
    getLeases() {
      this.loading = true;
      apiGetLeases(validateObjectNull(this.query)).then((res) => {
        this.$store.commit("setLeasesFilter", validateObjectNull(this.query));
          let arr = res.results;
           if (res.next == null) {
            this.nomore = true; //没有更多
            this.$message.warning("All has been loaded.");
          }else {
            this.nomore = false;
          }
          if(res.previous===null){
            this.total = res.count;
          }
          this.$store.commit("setPropertiesFilter", this.queryData);
          if(this.data.length===0){
            this.data = arr;
          }else {
            this.data = [...this.data, ...arr];
          }
          let obj = {};
          this.data = this.data.reduce((cur,next) => {
              obj[next.lease_id] ? "" : obj[next.lease_id] = true && cur.push(next);
              return cur;
          },[]) //设置cur默认类型为数组，并且初始值为空的数组
        }).finally((err) => {
          this.$refs.topSearch.popoverShow = false;
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getLeases();
    },
    selfFilter(val) {
      this.currentPage = 1;
      this.self = val;
      this.data = []
      this.getLeases();
    },
    signedFilter(val) {
      this.currentPage = 1;
      this.signed = val;
      this.data = []
      this.getLeases();
    },
    showCancelled(val){
      this.currentPage = 1;
      this.show_cancelled = val;
      this.data = []
      this.getLeases();
    },
    fuzzySearch(item) {// 模糊搜索
      this.currentPage = 1;
      this.search = item;
      this.data = []
      this.getLeases();
    },
    filterSearch(item) {
      this.currentPage = 1;
      this.data = []
      this.leaseInfor = Object.assign({}, item);
      this.getLeases();
    },
  },
  created() {
    if (this.$store.state.leasesFilter != null) {
      this.leaseInfor = Object.assign( {}, this.$store.state.leasesFilter, this.leaseInfor );
      this.self = this.$store.state.leasesFilter.self;
      this.signed = this.$store.state.leasesFilter.signed;
      this.show_cancelled = this.$store.state.leasesFilter.show_cancelled
      this.search = this.$store.state.leasesFilter.search;
      this.ordering = this.$store.state.leasesFilter.ordering;
      // this.currentPage = this.$store.state.leasesFilter.offset / this.limit + 1;
    }
    // this.getLeases();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/common.scss";
.lease_tip {
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 20px;
}
.show-number {
  text-align: left;
}
</style>
