<template>
  <div class="lease-table">
    <div class="table" ref="viewBox">
      <el-table :data="data" @sort-change="sort" @row-click="leaseDetail" @selection-change="handleSelectionChange" style="width:100%;height:auto;" v-el-table-infinite-scroll="load" >
        <el-table-column class="selection" type="selection"  width="46" ></el-table-column>
        <el-table-column label="LEASE #" width="100" prop="lease_id" sortable="custom" > </el-table-column>
        <el-table-column label="AGENT" prop="user_first_name" sortable="custom" min-width="120px"></el-table-column>
        <el-table-column label="CONTRACT DATE" prop="created_at" sortable="custom" min-width="130">
          <template slot-scope="scope">
            <p> {{ scope.row.contract_date | dateFormat }} </p>
          </template>
        </el-table-column>
        <el-table-column label="TENANT" prop="tenant_last_name"  sortable="custom" min-width="140"></el-table-column>
        <el-table-column label="OWNER" prop="owner_last_name" sortable="custom" min-width="140"></el-table-column>
        <el-table-column label="RENTAL ADDRESS" prop="listing_address" min-width="160"> </el-table-column>
        <el-table-column prop="arrival_date" sortable="custom" min-width="110" label="ARRIVAL">
          <template slot-scope="scope">
            <p> {{ scope.row.arrival_date | dateFormat }} </p>
          </template>
        </el-table-column>
        <el-table-column prop="departure_date" label="DEPARTURE" min-width="110" sortable="custom" >
          <template slot-scope="scope">
            <p> {{ scope.row.departure_date | dateFormat }} </p>
          </template>
        </el-table-column>
        <el-table-column label="PAYMENT STATUS" prop="status"
          sortable="custom" min-width="110">
          <template slot-scope="scope">
            <div class="scope_status">
              <span v-if="scope.row.status==='Paid in Full'" class="paid-in-full" >{{scope.row.status}}</span>
              <span v-else-if="scope.row.status==='Past Due'"  class="past-due">{{scope.row.status}}</span>
              <span v-else-if="scope.row.status==='Unsigned'"  class="unsigned">{{scope.row.status}}</span>
              <span v-else-if="scope.row.status==='Draft'"  class="draft">{{scope.row.status}}</span>
              <span v-else-if="scope.row.status==='Current'"  class="current">{{scope.row.status}}</span>
              <span v-else-if="scope.row.status==='Cancelled'"  class="cancelled">{{scope.row.status}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="arrival_date" label="NEXT DUE DATE" min-width="110" >
          <template slot-scope="scope">
            <p v-if="scope.row.status === 'Past Due'" style="color: red"  > {{ scope.row.next_due_date | dateFormat }}
            </p>
            <p v-else> {{ scope.row.next_due_date | dateFormat }} </p>
          </template>
        </el-table-column>
        <el-table-column prop="departure_date" label="LAST PAYMENT RECEIVED" min-width="100" >
          <template slot-scope="scope">
            <p> {{ scope.row.last_payment_date | dateFormat }} </p>
          </template>
        </el-table-column>
        <el-table-column prop="arrival_date" label="SECURITY DEPOSIT RETURNED" width="100" >
          <template slot-scope="scope">
            <p v-if="scope.row.security_deposit_status === 'Paid'" > Yes </p>
            <p v-else>No</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <mt-popup :modal="false" v-model="popupVisible" class="mt-popup" position="bottom">
         <el-radio-group class="row move-row" v-model="radioCsv" >
           <div class="subset">
            <el-radio-button label="podium_report" v-loading="loadingpodium"  @click.native.prevent="download('podium_report')">Podium Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="lease_report" v-loading="loadingLease"  @click.native.prevent="download('lease_report')">Lease Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="arrival_report" v-loading="loadingArrival" @click.native.prevent="download('arrival_report')">Arrival Report</el-radio-button>
          </div>
          <div class=" subset">
            <el-radio-button label="departure_report" v-loading="loadingDeparture" @click.native.prevent="download('departure_report')">Departure Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="welcome_report" v-loading="loadingWelcome"  @click.native.prevent="download('welcome_report')">Welcome Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="monthly_tax_report" v-loading="loadingMonthly"  @click.native.prevent="download('monthly_tax_report')">Monthly Tax Report</el-radio-button>
          </div>
      </el-radio-group>
      </mt-popup>
    <div class="footer-download">
      <div style="text-align: center">
        <el-radio-group class="row" v-model="radioCsv" >
          <div class="subset">
            <el-radio-button label="podium_report" v-loading="loadingpodium"  @click.native.prevent="download('podium_report')">Podium Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="lease_report" v-loading="loadingLease"  @click.native.prevent="download('lease_report')">Lease Report</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button label="arrival_report" v-loading="loadingArrival" @click.native.prevent="download('arrival_report')">Arrival Report</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button label="departure_report" v-loading="loadingDeparture" @click.native.prevent="download('departure_report')">Departure Report</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button label="welcome_report" v-loading="loadingWelcome"  @click.native.prevent="download('welcome_report')">Welcome Report</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button label="monthly_tax_report" v-loading="loadingMonthly"  @click.native.prevent="download('monthly_tax_report')">Monthly Tax Report</el-radio-button>
          </div>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import Paging from "../../common/Paging";
import { apiDownloadLeaseReport } from '../../../API/api'
import { downloadFile } from '../../../API/utils'
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
   directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
    },
  components: { Paging },
  props: ["data", "total", "currentPage"],
  data() {
    return {
      radioCsv: "",
      selectArr: [],
      loadingpodium: false,
      loadingLease:false,
      loadingArrival: false,
      loadingDeparture: false,
      loadingWelcome: false,
      loadingMonthly: false,
      popupVisible:false,
    };
  },
  methods: {
    load(){
      if(this.$refs.viewBox.scrollTop+ this.$refs.viewBox.offsetHeight+20 >= this.$refs.viewBox.scrollHeight){
          this.$emit("load");
       }
    },
    sort(val) {
      this.$emit("sort", val);
    },
    // 查看租约
    leaseDetail(item) {
      this.$router.push({ name: "edit-lease", params: { id: item.lease_id } });
    },
    pagesChanged(number) {
      this.$emit("pagesChanged", number);
    },
    handleSelectionChange(arr){
      if(arr.length!=0) this.popupVisible = true;
      else this.popupVisible = false
      this.selectArr = [];
       arr.forEach(item => {
         this.selectArr.push(item.lease_id)
       });
    },
    download(val){
      this.radioCsv = val;
      if(this.selectArr.length==0){
       this.$message.warning("Please select a lease");
      }else {
      let flag = "";
      if(val === "lease_report"){
        flag = "Lease Report";
        this.loadingLease = true;
      }else if(val === "arrival_report"){
        flag = "Arrival Report";
        this.loadingArrival = true;
      }else if(val === "departure_report"){
        flag = "Departure Report";
        this.loadingDeparture = true;
      }else if(val === "welcome_report"){
        flag = "Welcome Report";
        this.loadingWelcome = true;
      }else if(val === "monthly_tax_report"){
        flag = "Monthly Tax Report";
        this.loadingMonthly = true;
      }else if(val === 'podium_report'){
         this.loadingpodium = true;
         flag = "Podium Report"
      }
      apiDownloadLeaseReport({leases:this.selectArr,choice: flag}).then(res=>{
         downloadFile(res.data,res.headers["content-disposition"].split("=")[1]);
      }).finally(()=>{
        this.loadingpodium = false;
        this.loadingLease=false;
        this.loadingArrival= false;
        this.loadingDeparture= false;
        this.loadingWelcome= false;
        this.loadingMonthly= false
      })
      }
      
    },
     // 节流
    throttle(fn, delay, atleast) {
      let timer = null;
      let previous = null;
      return function () {
        var now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          // 重置上一次开始时间为本次结束时间
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
  },
   mounted(){
    this.$refs.viewBox.addEventListener('scroll', this.throttle(this.load,2000),false)
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/footerdownload.scss";
.table {
  font-size: 16px;
  // margin-bottom: 100px;
  .scope_status {
    .paid-in-full, .draft, .past-due,.unsigned, .current, .cancelled {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 100px;
      text-align: center;
      border-radius: 4px;
      color: #37a000;
      background-color: rgba(55, 160, 0, 0.1);
    }
    .draft {
      color: #FA6400;
      background-color: rgba(250, 100, 0, 0.1);
    }
    .cancelled {
      color: #DE1B4B;
      background-color: rgba(222, 27, 75, 0.1);
    }
    .unsigned {
      color: #F7B500;
      background-color: rgba(247, 181, 0, 0.1);
    }
    .current {
      color: #005ff7;
      background-color: rgba(0, 95, 247, 0.1);
    }
    .past-due {
      color: rgba(241, 26, 213, 1);
      background-color: rgba(241, 26, 213, 0.1);
    }
  }
  /deep/ .el-table {
    td {
      padding: 0;
    }
     .el-table__body-wrapper{
       -webkit-overflow-scrolling:touch;overflow-y:auto!important;
       height: 650px !important;
       @media (max-width:1400px) {
       height: 500px !important;
       }
     }
    }
}
.mt-popup{
  width: 100%;
  display: none;height: 200px;overflow: scroll;
  padding-top: 20px;
  .move-row{-webkit-flex-wrap: wrap;flex-wrap: wrap;}
   .subset{width: calc(calc(100% - 80px) / 2);min-width: 200px; padding: 0 20px; margin:10px 0 0 0;
     /deep/ .el-radio-button{width: 100%;
      .el-radio-button__inner{color: #678993;border: 1px solid #678993; box-sizing: border-box; border-radius: 4px;width: 100%;}}
  }
}
@media (max-width: 992px) {
  .mt-popup{display: block ;}
}
</style>