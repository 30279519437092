<template>
  <div class="lease-top-search">
    <div class="top-content pc row">
      <div class="top-content-left row">
        <h3 class="top-conten-title">Leases</h3>
        <div>
          <p style="margin: 0; text-align: left">
            <el-checkbox v-model="self" @change="selfFilter"
              >Show Only My Leases</el-checkbox
            >
          </p>
          <p style="margin: 0; text-align: left">
            <el-checkbox v-model="signed" @change="signedFilter"
              >Show Only Complete Leases</el-checkbox
            >
          </p>
          <p style="margin: 0; text-align: left">
            <el-checkbox v-model="show_cancelled" @change="showCancelled"
              >Show Cancelled Leases</el-checkbox
            >
          </p>
        </div>
      </div>
      <div class="top-content-right row">
        <el-input placeholder="Search by Address, Owner, Tenant, Lease # or Email" v-model="search" class="input-with-select" @keyup.enter.native="fuzzySearch">
          <i slot="prefix" @click="fuzzySearch" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-popover popper-class="placement-class" placement="bottom" class="popup" width="660" trigger="click" v-model="popoverShow">
          <div class="content">
            <Lease-Filter class="pc" ref="child" @close="close" @precisionSearch="precisionSearch"></Lease-Filter>
          </div>
          <el-button slot="reference" class="filter row">
            <img src="../../../assets/icon/ico-filter.svg" alt />
            <span>Filter</span>
          </el-button>
        </el-popover>
        <el-button class="light-blue-button ml-3" @click="reset">Clear Filter</el-button>
        <el-button class="primary-button" @click="createLease">+ New Lease</el-button>
      </div>
    </div>
    <div class="move">
      <div class="row item-row">
        <h3 class="top-conten-title">Leases</h3>
        <el-button class="primary-button" @click="createLease">+ New Lease</el-button>
      </div>
      <div class="row item-row"> 
        <el-input placeholder="Search by Address, Owner, Tenant, Lease # or Email" v-model="search" class="input-with-select move-input" @keyup.enter.native="fuzzySearch">
          <i slot="prefix" @click="fuzzySearch" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button v-if="popupVisible ===false" style="padding: 1px 5px" @click="popupVisible = true ">
          <img class="filter-img" src="../../../assets/icon/ico-filter.svg" alt />
        </el-button>
        <el-button v-else style="padding: 1px 5px" @click="popupVisible = false ">
          <img class="filter-img" src="../../../assets/icon/ico-filter.svg" alt />
        </el-button>
        <mt-popup :modal="false" v-model="popupVisible" class="mt-popup" position="bottom">
         <div class="mt-popup-contact"><Lease-Filter ref="childMove" @close="close" @precisionSearch="precisionSearch"></Lease-Filter></div>
        </mt-popup>
      </div>
      <div>
        <p style="margin: 0; text-align: left">
          <el-checkbox v-model="self" @change="selfFilter" >Show Only My Leases</el-checkbox>
        </p>
        <p style="margin: 0; text-align: left">
          <el-checkbox v-model="signed" @change="signedFilter">Show Only Complete Leases</el-checkbox>
        </p>
        <p style="margin: 0; text-align: left">
          <el-checkbox v-model="show_cancelled" @change="showCancelled">Show Cancelled Leases</el-checkbox>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import LeaseFilter from "./LeaseFilter";
export default {
  components: { LeaseFilter },
  data() {
    return {
      signed: false,
      self: true,
      search: "",
      popoverShow: false,
      show_cancelled: false,
      popupVisible: false,
    };
  },
  methods: {
    reset() {
      this.search = "";
      this.self = true;
      this.show_cancelled = false;
      this.signed = false;
      this.$refs.child.clear();
      this.$refs.childMove.clear();
      this.$emit("reset");
    },
    close() {
      this.popoverShow = false;
      this.popupVisible = false;
    },
    // 模糊搜索
    fuzzySearch() {
      this.$emit("fuzzySearch", this.search);
    },
    // 创建租约
    createLease() {
      this.$router.push({ name: "create-lease" });
      this.$store.commit("setLeaseDetail", false);
      this.$store.commit("setLeaseAgeent", false);
      this.$store.commit("setLeaseParam", {});
      this.$store.commit("setLeaseSecurityDeposit", false);
    },
    precisionSearch(val) {
      this.$emit("filterSearch", val);
    },
    selfFilter(val) {
      this.$emit("selfFilter", val);
    },
    signedFilter(val) {
      this.$emit("signedFilter", val);
    },
    showCancelled(val) {
      this.$emit("showCancelled", val);
    },
  },
  mounted() {
    if (this.$store.state.leasesFilter != null) {
      this.self = this.$store.state.leasesFilter.self;
      this.signed = this.$store.state.leasesFilter.signed;
      this.search = this.$store.state.leasesFilter.search;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.lease-top-search {
  .row{
    margin:0px;
  }
  .top-content {
    justify-content: space-between;
    .row{
      margin:0px;
    }
    .top-content-left {
      width: 300px;
      /deep/ .el-checkbox__label {
        color: #000;
        font-size: 14px;
        font-family: "Roboto-Regular", sans-serif;
      }
    }
    .top-content-right {
      width: calc(100% - 320px);
      justify-content: flex-end;
      .create-lease {
        color: #fff;
        background-color: #678993;
        font-family: "Roboto-Medium", sans-serif;
        border-color: #678993;
      }
    }
  }
  .top-conten-title {
    font-size: 24px;
    font-family: "Roboto-Bold", sans-serif;
    margin: 0 20px 0 10px;
    min-width: 80px;
  }
  .filter {
    background-color: #fff;
    margin-right: 10px;
    min-width: 110px;
    text-align: center;
    height: 40px;

    &.row{
      margin:0px;
    }
    /deep/ span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    img{
      width:18px;
    }
  }
  .input-with-select {
    width: 460px;
    margin-right: 10px;
    @media (max-width: 1400px) {
      width: calc(100% - 55px);
    }
    .el-icon-search {
      color: #678993;
    }
    /deep/ ::-webkit-input-placeholder {
      color: #38425b;
      opacity: 0.6;
    }
    /deep/ ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #38425b;
      opacity: 0.6;
    }
    /deep/ :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #38425b;
      opacity: 0.6;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }
  }
  .move {
    display: none;
    .item-row {
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;
      .mt-popup{
        width: 100%;
        height: calc(100% - 300px);
        box-shadow: 0px -10px 20px rgba(17, 61, 71, 0.05);
        overflow: scroll;
        z-index: 1008 !important;
        .mt-popup-contact{padding: 20px;}
      }
    }
    .filter-img{
      height: 33px;
    }
    .move-input/deep/ .el-input__inner{
      height: 40px;
    }
  }
  @media (max-width: 992px) {
    .pc {
      display: none;
    }
    .move {
      display: block;
    }
  }
}
</style>